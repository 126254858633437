@font-face {
  font-family: "Poppins-Regular";
  src: url("./Assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./Assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./Assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf")
    format("truetype");
}
@font-face {
  font-family: "JosefinSans";
  src: url("./Assets/fonts/JosefinSans/JosefinSans-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "JosefinSans-Bold";
  src: url("./Assets/fonts/JosefinSans/JosefinSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "JosefinSans-Bold";
  src: url("./Assets/fonts/JosefinSans/JosefinSans-Medium.ttf")
    format("truetype");
}
@font-face {
  font-family: "PT Sans";
  src: url("./Assets/fonts/PTSans/PTSans-Regular.ttf") format("truetype");
}

.poppins {
  font-family: "Poppins-Regular" !important;
}
.poppinsBold {
  font-family: "Poppins-Bold" !important;
}
.pointer {
  cursor: pointer;
}
.no-scroll {
  overflow: hidden !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
  font-family: "Montserrat" !important;
}
/* Target the element you want to customize the scroll bar for */
body {
  /* Set the width of the scrollbar */
  scrollbar-width: thin; /* "thin", "auto", or "none" based on your preference */
}

/* Target the scrollbar thumb and track in WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
}

/* Set the color of the scrollbar thumb in WebKit-based browsers */
::-webkit-scrollbar-thumb {
  background-color: #5c5c5c2d; /* Color of the scrollbar thumb */
}

/* Set the color of the scrollbar track in WebKit-based browsers */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}

/* Target the scrollbar thumb and track in Mozilla-based browsers (Firefox) */
::-moz-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Set the color of the scrollbar thumb in Mozilla-based browsers */
::-moz-scrollbar-thumb {
  background-color: #55555541; /* Color of the scrollbar thumb */
}

/* Set the color of the scrollbar track in Mozilla-based browsers */
::-moz-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}

body:hover {
  overflow: auto;
}
.text-red-600 {
  color: #f00;
}
.text-green-600 {
  color: rgb(16, 170, 2);
}
